<template>
    <div class="statistics" :style="{color:colorVal}">
        <!-- <img src="https://www.baidu.com/img/flexible/logo/pc/result.png" alt=""> -->

        <img :src="imgVal" alt="">
        <!-- <i class="fa" :class="imgVal"></i> -->


        <div class="contentbox" :style="{color:colorVal}" v-if="!typeVal">

            <div>
                <span>医废</span><a>{{label}}</a><span>统计</span>
            </div>
            <div>
                <a><b>{{num}}</b> 包，共 <b>{{weight}} KG</b></a>
            </div>
            <div class="line">
                <span>医废</span><a>{{label}}汇总</a><span>详情</span>
            </div>

        </div>


        <div class="contentbox" :style="{color:colorVal}" v-else>

            <div>
                <span>超</span><a>{{typeVal}}小时</a><span>未处理</span>
            </div>
            <div>
                <a><b>{{num}}</b> 包，共 <b>{{weight}} KG</b></a>
            </div>
            <div class="line">
                <span>{{num>0?'':'未'}}存在</span><a>超{{typeVal}}小时</a><span>未处理医废</span>
            </div>
            <a @click="info" class="info" :style="{backgroundColor:colorVal}">查看详情</a>

        </div>


    </div>
</template>

<script>
    export default {

        props: ["colorVal", "imgVal", "typeVal", "label", "num", "weight", "time"],
        data() {
            return {
                // type: 0
            }
        },
        watch: {
            // typeVal(value) {
            //     this.type = this.typeVal
            //     console.log("AAAAAAAAAAAAAAAAAAAA", this.value);
            // }
        },
        methods: {

            info() {

                this.$parent.timeFun(this.time)


            }


        }
    }
</script>

<style lang="scss" scoped>
    .statistics {
        // border: 1px red solid;
        border-left: 1px #ECECEC solid;
        box-sizing: content-box;
        float: left;
        height: 80px;

        position: relative;

        width: 100%;
        margin: 30px 0;
        margin-top: 20px;
        margin-left: -2px;

        display: flex;
        flex-direction: row;
        // justify-content: space-evenly;
        justify-content: center;
        align-items: center;

        img,
        i {
            // border: 1px red solid;
            box-sizing: content-box;
            width: 50px;
            height: 50px;

            line-height: 50px;
            text-align: center;
            color: inherit;
            font-size: 36px;
        }



        .contentbox {
            // border: 1px red solid;
            box-sizing: content-box;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            // color: #606060;
            margin-left: 5px;

            div {
                a {
                    color: inherit;
                }

                b {
                    font-size: 18px;
                }

                span {
                    color: #000;
                }
            }

            .line {
                border-top: 2px solid;
                box-sizing: content-box;
                margin-top: 2px;
                padding-top: 2px;
            }

            .info {
                border: 1px solid;
                // box-sizing: content-box;
                // background-color: inherit;
                // float: right;
                height: 20px;
                line-height: 20px;
                padding: 0px 10px;
                border-radius: 12px;
                font-size: 12px;
                cursor: pointer;
                color: white;
                position: absolute;
                right: 10px;
            }
        }
    }
</style>